<template>
  <b-container fluid class="mb-4">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Detalhes</h4>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-form>
            <b-form-row>
              <b-col>
                <form-input
                  :value="schedulingDateFormat.nome"
                  label="Nome:"
                  icon="person"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="schedulingDateFormat.telefone"
                  label="Telefone:"
                  icon="telephone"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="schedulingDateFormat.atendido"
                  label="Situação:"
                  icon="telephone-outbound"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="schedulingDateFormat.responsavel"
                  label="Responsável pela ligação:"
                  icon="person-circle"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="schedulingDateFormat.data_atendido"
                  label="Data da ligação:"
                  icon="calendar3"
                  disabled
                />
              </b-col>
            </b-form-row>

            <form-textarea
              :value="schedulingDateFormat.informacoes"
              label="Considerações finais:"
              icon="card-text"
              rows="10"
              no-resize
              disabled
            />
          </b-form>
        </b-card-text>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";

export default {
  components: { FormInput, FormTextarea },
  name: "WeCallYouDetails",
  data() {
    return {};
  },
  computed: {
    ...mapState("weCallYou", ["scheduling", "schedules", "isLoading"]),

    schedulingDateFormat() {
      return {
        ...this.scheduling,
        atendido: this.scheduling.atendido
          ? "Ligação realizada"
          : "Aguardando ligação",
        data_atendido: dayjs(this.scheduling.data_atendido)
          .add(3, "hours")
          .format("DD/MM/YYYY HH:mm:ss"),
      };
    },
  },
  methods: {
    ...mapActions("weCallYou", ["getOneScheduling"]),
    ...mapMutations(["setOverlay"]),
  },
  async created() {
    this.setOverlay(true);
    try {
      if (!this.scheduling.id) {
        const { id } = this.$route.params;
        this.getOneScheduling(id);
      }
    } catch (erro) {
      console.log(erro);
    }
    this.setOverlay(false);
  },
};
</script>

<style></style>
