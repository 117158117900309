<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-input-group-prepend v-if="icon" is-text>
        <b-icon :icon="icon" />
      </b-input-group-prepend>
      <b-form-textarea
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :rows="rows"
        :max-rows="maxRows"
        :no-resize="noResize"
        :state="state"
        @input="$emit('input', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    rows: {
      type: [String, Number],
      required: false,
    },
    maxRows: {
      type: [String, Number],
      required: false,
    },
    noResize: {
      type: Boolean,
      required: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
};
</script>

<style></style>
