<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-input-group-prepend
        v-if="icon"
        is-text
        :class="{ 'icon-button': copyable }"
        :title="copyable && 'Copiar texto para área de transferência'"
        @click="useClipboard"
      >
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :type="type"
        :spellcheck="spellcheck"
        @keypress.enter="$emit('enter', $event)"
        @input="$emit('input', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
    },
    spellcheck: {
      type: Boolean,
      required: false,
    },
    copyable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      toggleEye: true,
    };
  },
  methods: {
    useClipboard() {
      if (this.copyable) {
        navigator.clipboard.writeText(this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
}
</style>
